.App {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(18, 18, 19);
  width: 100vw;
  min-height: -webkit-fill-available;
  height: 100%;
  /* overflow: auto; */
  /* padding: 24px 0 12px 0; */
}

.App-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0 12px 0;
}

.letter-key {
  background-color: rgb(129, 131, 132);
  margin: 3px;
  width: calc(100% / 12.5);
  border-radius: 4px;
  height: 55px;
  color: white;
}

.letter-key-dark {
  background-color: rgb(58, 58, 60);
  margin: 3px;
  width: calc(100% / 12.5);
  border-radius: 4px;
  height: 55px;
  color: white;
}

.letter-key-gold {
  background-color: rgb(129, 131, 132);
  margin: 3px;
  width: calc(100% / 12.5);
  border-radius: 4px;
  height: 55px;
  color: rgb(246, 205, 21);
}

ins {
  width: 100%;
  height: 100%;
}